import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import MoviesPage from "./components/movies-page";
import HomePage from "./components/home-page";
import Navigation from "./components/navigation";
import "./App.css";

const TITLES = {
  "/": "vkine.art: Program pražských kin, přehledně a rychle",
  "/dnes": "vkine.art: Dněšní program, další dny, vyhledávání",
  "/novinky": "vkine.art: Plánované premiéry",
  "/speciality": "vkine.art: Obnovené premiéry a rarity",
  "/vse": "vkine.art: Všechny filmy",
};

function TitleUpdater() {
  const location = useLocation();
  const title = TITLES[location.pathname] || "vkine.art";

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}

const App = () => {
  return (
    <HelmetProvider>
      <Router>
      <TitleUpdater />
        <div className="container">
          <div className="column-menu">
            <Navigation />
          </div>
          <div id="movies-container" className="column-content">
            <Routes>
              <Route
                key="page-today"
                path="/dnes"
                element={
                  <MoviesPage
                    endpoint="/s-movies"
                    title="V kinech"
                    showRange={true}
                  />
                }
              />
              <Route
                key="page-premieres"
                path="/novinky"
                element={
                  <MoviesPage
                    endpoint="/s-premieres"
                    title="Tipy pro náročné"
                    premiereVisible={true}
                  />
                }
              />
              <Route
                key="page-rares"
                path="/speciality"
                element={
                  <MoviesPage
                    endpoint="/s-rares"
                    title="Obnovené premiéry a rarity"
                  />
                }
              />
              <Route
                key="page-all"
                path="/vse"
                element={
                  <MoviesPage
                    endpoint="/s-all"
                    title="Všechno co máme"
                  />
                }
              />
              <Route path="/" element={<HomePage />} />
            </Routes>
          </div>
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
